import { useState } from 'react';
import {
  Heading,
  Link,
  Logo,
  Text,
  Button,
  Icon,
} from '@requity-homes/component-library';
import Image from 'next/image';

export interface IntroSlidesProps {
  setShowIntroSlides: (show: boolean) => void;
}

export function IntroSlides({ setShowIntroSlides }) {
  const [showNextSlide, setShowNextSlide] = useState(false);

  return (
    <main className="flex flex-col flex-1 justify-center items-center h-full">
      <div className="flex flex-col justify-center items-center w-full md:w-3/6 lg:w-2/6 px-8 shadow-lg py-6 h-full">
        <Link href="/" className="mb-4">
          <Logo color="coral" className="h-8" />
        </Link>
        {showNextSlide ? (
          <div className="flex flex-col h-full justify-between">
            <div className="flex flex-col gap-12 h-full">
              <div className="flex justify-center items-center w-56 h-42 self-center -mt-4 -mb-4">
                <Icon glyph="inviteClients" className="w-full h-full" />
              </div>
              <div className="flex flex-col gap-3 md:gap-6 -mt-8 md:mt-0">
                <div className="flex items-start justify-start">
                  <Heading
                    level="h2"
                    element="h2"
                    fontFace="sans"
                    className="mr-4 !leading-none"
                  >
                    1
                  </Heading>
                  <div className="flex flex-col gap-1">
                    <Heading level="h5" element="h5" fontFace="sans">
                      Invite your clients
                    </Heading>
                    <Text>
                      Your unique referral link ensures that the invited clients
                      will be attributed to you.
                    </Text>
                  </div>
                </div>
                <div className="flex items-start justify-start">
                  <Heading
                    level="h2"
                    element="h2"
                    fontFace="sans"
                    className="mr-4 !leading-none"
                  >
                    2
                  </Heading>
                  <div className="flex flex-col gap-1">
                    <Heading level="h5" element="h5" fontFace="sans">
                      Check their application status
                    </Heading>
                    <Text>
                      Check you clients’ application status and send reminders
                      easily.
                    </Text>
                  </div>
                </div>
                <div className="flex items-start justify-start">
                  <Heading
                    level="h2"
                    element="h2"
                    fontFace="sans"
                    className="mr-4 !leading-none"
                  >
                    3
                  </Heading>
                  <div className="flex flex-col gap-1">
                    <Heading level="h5" element="h5" fontFace="sans">
                      Earn full commission
                    </Heading>
                    <Text>
                      Once your fully approved clients find their dream home,
                      you will earn your full commission!
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <Button
              type="button"
              className="shadow-md-dark sm:self-center bg-coral-med border-coral-med text-white w-full sm:py-1 flex items-center justify-center !px-0 -mt-6"
              color="coral"
              hierarchy="primary"
              onClick={() => setShowIntroSlides(false)}
            >
              Next
            </Button>
          </div>
        ) : (
          <div className="flex flex-col h-full justify-between">
            <div className="flex flex-col gap-8 h-full">
              <div className="flex justify-center items-center w-52 h-42 self-center mt-4">
                <Icon glyph="thankYou" className="w-full h-full" />
              </div>
              <div className="flex flex-col gap-3 md:gap-6 -mt-6 md:mt-0 mb-8">
                <Heading
                  fontFace="sans"
                  level="h2"
                  element="h3"
                  className="px-4 text-center"
                >
                  Thank you for partnering with Requity Homes!
                </Heading>
                <Text className="px-4 text-center">
                  Invite your clients who aren’t mortgage-ready today to apply
                  for rent-to-own program. Help them become homeowners sooner
                  while earning your full commission.
                </Text>
              </div>
            </div>

            <Button
              type="button"
              className="shadow-md-dark sm:self-center bg-coral-med border-coral-med text-white w-full sm:py-1 flex items-center justify-center !px-0 -mt-6"
              color="coral"
              hierarchy="primary"
              onClick={() => setShowNextSlide(true)}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </main>
  );
}
